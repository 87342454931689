#root {
  height: 100vh;
}
.editor {
  height: 100%;
  flex-grow: 2;
}
.viewer {
  height: 80%;
}
.helper {
  margin-top: 55px;
}
#webpack-dev-server-client-overlay {
  display: none;
}
